<template>
  <v-dialog v-if="itemsCount" v-model="dialog" width="800" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="itemsCount"
        :value="itemsCount"
        :style="`position: fixed; bottom: 20px; z-index: 80; left: ${floatBtnLeftPosition}px`"
        color="green"
        overlap
      >
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="getLabels()"
          class="amber lighten-1 msaBlue--text"
          elevation="5"
          large
        >
          <v-icon class="pr-2"> mdi-file-document-multiple-outline </v-icon>
          Selected Resources
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col> Mass Management </v-col>
          <v-col class="shrink">
            <v-btn @click="close()" icon>
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row>
          <v-col cols="12">
            <CustomizedAutoComplete
              @change="updateGroup($event)"
              :value="selectedGroup"
              :items="groups"
              clearable
              hide-details
              item-name="name"
              item-value="id"
              label="Select a folder to move resources to"
              prepend-icon="mdi-folder-outline"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <CustomizedAutoComplete
              @change="selectedLabels = $event"
              :value="selectedLabels"
              :items="labels"
              clearable
              deletable-chips
              hide-details
              label="Attach labels(s)"
              multiple
              prepend-icon="mdi-tag-multiple-outline"
            />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="shrink pr-0">
            <v-icon>mdi-file-eye-outline</v-icon>
          </v-col>
          <v-col>
            <PublishButtonGroup
              @click="updatePublish($event)"
              :is-published="isPublishedGlobalState"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-simple-table :key="reloadTable">
                <template v-slot:default>
                  <thead class="lightBg">
                    <tr>
                      <th>Selected Files</th>
                      <th>Actions</th>
                      <th class="text-right">Remove From Selection</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(doc, index) in toEditDocs" :key="doc.id">
                      <td>
                        {{ doc.name }}
                      </td>
                      <td>
                        <PublishButtonGroup
                          @click="doc.isPublished = $event"
                          :is-published="doc.isPublished"
                        />
                      </td>
                      <td align="right">
                        <v-btn @click="unselect(doc, index)" color="red" icon>
                          <v-icon> mdi-minus-circle-outline </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="red" dark @click="massDelete">
          <v-icon>mdi-delete</v-icon>
          delete all
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="cancel" text dark color="red">Cancel</v-btn>
        <v-btn @click="onSave()" class="msaBlue white--text"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PublishButtonGroup from '@/components/PublishButtonGroup';
export default {
  name: 'PublicDocumentMassManage',
  components: { PublishButtonGroup },
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },
  watch: {
    dialog: {
      handler(val) {
        if (val) {
          this.ogDocuments = [];
          this.selectedLabels = [];
          this.selectedGroup = null;
          this.generateEditableArr();
        }
      },
    },
  },
  data() {
    return {
      dialog: false,
      labels: [],
      toEditDocs: [],
      reloadTable: false,
      selectedGroup: null,
      selectedLabels: [],
      showLoader: false,
      payload: {
        documents: [],
        groupChange: [],
        publishedChange: [],
        selectedLabels: [],
      },
    };
  },
  computed: {
    itemsCount() {
      return this.$store.getters.selectedPublicDocuments.length;
    },
    floatBtnLeftPosition() {
      return window.innerWidth / 2 - 249 / 2;
    },
    isPublishedGlobalState() {
      if (!this.toEditDocs.length) {
        return; // Avoids console error before generateEditableArr is called
      }

      const initVal = this.toEditDocs[0].isPublished;
      const result = this.toEditDocs.find((doc) => doc.isPublished != initVal);

      if (result == undefined) {
        return initVal ? 1 : 0;
      }

      return null; // Indeterminate state
    },
    selectedDocuments() {
      return this.$store.getters.selectedPublicDocuments;
    },
  },
  methods: {
    unselect(doc, index) {
      this.$store.commit('updateSelectedPublicDocuments', {
        items: [doc],
        value: false,
      });

      this.toEditDocs.splice(index, 1);

      if (this.itemsCount == 0) {
        this.resetPayload();
        this.close();
      }
    },
    generateEditableArr() {
      this.toEditDocs = JSON.parse(JSON.stringify(this.selectedDocuments));
    },
    getLabels() {
      const url = 'get-all-attachable-labels?format=json';
      const params = {
        loaderText: 'Loading... ',
      };

      this.$axios.post(url, params).then((response) => {
        this.labels = response.data;
      });
    },
    buildPayload() {
      this.resetPayload();

      for (let i = 0; i < this.selectedDocuments.length; i++) {
        // selectedGroup can be null and that means groupId was cleared
        if (
          this.selectedDocuments[i].groupId != this.selectedGroup &&
          this.selectedGroup
        ) {
          this.payload.groupChange.push({
            id: this.toEditDocs[i].id,
            groupId: this.selectedGroup,
          });
        }

        if (
          this.selectedDocuments[i].isPublished !=
          this.toEditDocs[i].isPublished
        ) {
          this.payload.publishedChange.push({
            id: this.toEditDocs[i].id,
            isPublished: this.toEditDocs[i].isPublished,
          });
        }
      }

      // Used for labels
      this.payload.documents = this.selectedDocuments;
      this.payload.selectedLabels = this.selectedLabels;
    },
    onSave() {
      const url = 'mass-manage-resources';
      this.buildPayload();
      const params = { payload: this.payload, loaderText: 'Loading...' };

      this.$axios.post(url, params).then(() => {
        this.cancel(true);
      });
    },
    cancel(autoClose = false) {
      const confirm = () => {
        this.toEditDocs = [];
        this.resetPayload();
        this.close();
        this.$emit('onCancel');
      };

      if (autoClose) {
        confirm();
        return;
      }

      this.$root.showMessage(
        'Confirm',
        'Selections will be undone. Are you sure you want to exit?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
    massDelete() {
      const confirm = () => {
        this.$axios
          .post(url, params)
          .then(() => this.cancel(true))
          .catch((error) => error);
      };

      const url = 'mass-delete-resources';
      const params = {
        loaderText: 'Loading...',
        resources: this.selectedDocuments.map((resource) => resource.id),
      };

      this.$root.showMessage(
        'Confirm',
        'Selections will be deleted. Are you sure you want to continue?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
    resetPayload() {
      this.payload = {
        groupChange: [],
        publishedChange: [],
        selectedLabels: [],
      };
    },
    close() {
      this.dialog = false;
    },
    updatePublish(val) {
      this.toEditDocs.forEach((document) => {
        document.isPublished = val;
      });
    },
    updateGroup(val) {
      this.selectedGroup = val;
    },
  },
};
</script>
